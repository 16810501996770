import { Injectable } from '@angular/core';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { BehaviorSubject, from } from 'rxjs';

import { AwsAmplifyService } from '@app/services/auth/aws-amplify.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User | null;

  user$ = new BehaviorSubject<User | null>(null);

  constructor(private awsAmplifyService: AwsAmplifyService) {}

  initSessionUser() {
    const p = this.awsAmplifyService
      .currentSession()
      .then((session) => {
        if (session.isValid()) {
          const token = session.getIdToken().getJwtToken();
          const payload = session.getIdToken().decodePayload();

          this.user = {};
          this.user.token = token;
          this.user.picture = payload.picture as string;
          this.user.email = payload.email as string;
          this.user.fullname = `${payload.given_name as string} ${payload.family_name as string}`;

          return this.user$.next(this.user);
        }

        throw new Error('Invalid session: initSessionUser');
      })
      .catch((error: Error) => console.log(error.message || error));

    return from(p);
  }

  federatedSignIn(provider: CognitoHostedUIIdentityProvider, customState?: string) {
    const p = this.awsAmplifyService
      .federatedSignIn(provider, customState)
      .catch((error: Error) => console.log(error.message || error));

    return from(p);
  }

  signIn(username: string, password: string) {
    const p = this.awsAmplifyService
      .signIn(username, password)
      .catch((error: Error) => console.log(error.message || error));

    return from(p);
  }

  hasSession() {
    const p = this.awsAmplifyService
      .currentSession()
      .then((session) => session.isValid())
      .catch((error: Error) => console.log(error.message || error));

    return from(p);
  }

  signOut() {
    sessionStorage.setItem(redirectUrlKey, window.location.href);

    const p = this.awsAmplifyService
      .signOut()
      .then(() => {
        this.user = null;
        this.user$.next(this.user);
      })
      .catch((error: Error) => console.log(error.message || error));

    return from(p);
  }
}

export type User = { token?: string; fullname?: string; picture?: string; email?: string };

export const redirectUrlKey = 'redirectUrl';
