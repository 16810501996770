export enum PortalFeatures {
  portal_cc = 'portal_cc',
  portal_auth = 'portal_auth',
  portal_hidden_flights = 'portal_hidden_flights',
  portal_hidden_flights_v2 = 'portal_hidden_flights_v2',
}

export type PortalFlightRequestNode = {
  portalLink: string;
  publishedOptions: {
    edges: {
      node: PortalOrderNode;
    }[];
  };
  isLost: boolean;
  createdAt: string;
};

export type PortalOrderNode = {
  portalLink: string;
  status: PortalOrderStatusType;
  portalDescription: string;
  soldAmount: number;
  flightItineraries: PortalFlightItineraryGroupNode[];
  alphaName: string;
  onlinePrice: number;
  savePrice: number;
  bookingStatus: PortalBookingStatusTypeEnum;
  numberOfAdults: number;
  numberOfChildren: number;
  numberOfInfants: number;
  totalPassengers: number;
  soldAmountPerAdult: number;
  soldAmountPerChild: number;
  soldAmountPerInfant: number;
  hasThrowawayTicketing: boolean;
  hasHiddenCityTicketing: boolean;
  hotelBookings: PortalHotelBooking[];
  disruptionProtectionAmount: number;
  disruptionProtectionAmountPerPaidPassenger: number;
  disclaimersHtml: string;
  disclaimersWithAdditionalProductsHtml: string;
  cancelForAnyReasonAmount: number;
  cancelForAnyReasonAmountPerPaidPassenger: number;
  cancelForAnyReasonBalanceRefundPerTraveler: number;
  cancelForAnyReasonRefundPerTraveler: number;
  isCancelForAnyReasonEnabled: boolean;
  isDemo: boolean;
  isChange: boolean;
  hiddenFlightsPresented: boolean;
};

export type PortalAirlineNode = {
  logoUrl: string;
  shortName: string;
};

export type PortalAirportNode = {
  locationCode: string;
  cityName: string;
  country: PortalCountryNode;
};

export type PortalCountryNode = {
  id: string;
  name: string;
};

export type LayoverNode = {
  waitTime: TotalTravelTimeNode;
  selfTransfer: boolean;
};

export type PortalFlightItinerarySegmentNode = {
  flightSegment: PortalFlightSegmentNode;
  layover: LayoverNode;
  travelTime: TotalTravelTimeNode;
  longest: boolean;
  nextDayDeparture: boolean;
  nextDayArrival: boolean;
};

export type PortalFlightSegmentNode = {
  departureAtAirportTimeZone: string;
  arrivalAtAirportTimeZone: string;
  serviceClass: string;
  airline: PortalAirlineNode;
  flightNumber: string;
  aircraftName: string;
  operatedBy: string;
  departureAirport: PortalAirportNode;
  arrivalAirport: PortalAirportNode;
  isOvernight: boolean;
};

export type PortalFlightItineraryGroupNode = {
  travelTime: PortalTotalTravelTimeNode;
  departureAirport: PortalAirportNode;
  arrivalAirport: PortalAirportNode;
  finalDestinationAirport: PortalAirportNode;
  departureAt: Date;
  daysShift: number;
  segments: PortalFlightItinerarySegmentNode[];
};

export type TotalTravelTimeNode = {
  days: number;
  hours: number;
  minutes: number;
};

export type PortalTotalTravelTimeNode = TotalTravelTimeNode; // todo merge the types

export type PortalHotelBooking = {
  name: string;
  guestCount: number;
  city: string;
  checkInDate: string;
  checkoutDate: string;
};

export type PortalLeadNode = {
  firstName: string;
  lastName: string;
  clientHasGmail: boolean;
  salesAgent: PortalSalesAgentNode;
  firstTrip?: PortalLeadFirstTripNode;
  flightRequest: PortalFlightRequestNode;
  roundTripDepartureAt: Date;
  tripType: PortalTripType;
};

export type PortalSalesAgentNode = {
  fullName: string;
  picture: string;
  email: string;
  phone: string;
  deals: number;
  avgSavings: number;
  portalFeatures: PortalFeatures[];
  shift: ShiftNode;
};

export type ShiftNode = {
  startDateTimeAtUtc: string;
  endDateTimeAtUtc: string;
};

export enum PortalTripType {
  Oneway = 'ONEWAY',
  Round = 'ROUND',
  Multi = 'MULTI',
}

export type PortalLeadFirstTripNode = {
  departureAt: Date;
  serviceClass: string;
  passengerCount: number;
  departureAirport: PortalAirportNode;
  arrivalAirport: PortalAirportNode;
};

export enum PortalBookingStatusTypeEnum {
  Option = 'OPTION',
  Booked = 'BOOKED',
  InProgress = 'IN_PROGRESS',
  Issued = 'ISSUED',
}

export enum PortalOrderStatusType {
  Option = 'OPTION',
  OptionBooked = 'OPTION_BOOKED',
}

export enum PortalGenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export type AuthorizedDataNode = {
  passengers: PortalPassengerNode[];
  creditCards: PortalCreditCardNode[];
  verification: PortalUserVerificationNode;
};

export type PortalPassengerNode = {
  id: string;
  countryId: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName: string;
  birthday: Date;
  gender: PortalGenderType;
};

export type PortalCreditCardNode = {
  id: string;
  number: string;
  holder: string;
  expiration: string;
  type: CreditCardTypeEnum;
};

export enum CreditCardTypeEnum {
  VISA = 'VISA',
  MC = 'MC',
  AMEX = 'AMEX',
  DISC = 'DISC',
  D = 'D',
  JCB = 'JCB',
  UNKNOWN = 'UNKNOWN',
}

export type PortalUserVerificationNode = {
  required: boolean;
  emailMasked: string;
  emailSentAt: string;
};

export type PortalPassengerNodeInput = {
  gender: PortalGenderType;
  firstName: string;
  lastName: string;
  birthday: Date;
  passportCountryId: string;
  middleName?: string;
};

export type PortalCreditCardNodeInput = {
  id?: string;
  holder?: string;
  number?: string;
  expiration?: string;
  cvv2: string;
  city?: string;
  state?: string;
  zipCode?: string;
  billingAddress1?: string;
  billingAddress2?: string;
  countryId?: string;
};
