import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs';

import { Option } from '@app/forms//formly/formly-utils';

@Component({
  selector: 'dropdown-field',
  templateUrl: './dropdown-field.component.html',
  styleUrls: ['./dropdown-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownFieldComponent extends FieldType<
  FieldTypeConfig<FormlyFieldConfig['props'] & { options: Observable<Option[]> }>
> {}
