import { Option } from '@app/forms/formly/formly-utils';
import {
  PortalCreditCardNode,
  PortalPassengerNode,
  PortalFlightItineraryGroupNode,
} from '@app/services/api/api.types';
import { toLabel } from '@app/ui/pipes/label.pipe';

export const scrollTo = (el: Element | string) => {
  setTimeout(() => {
    if (typeof el === 'string') {
      el = document.querySelector(el) as Element;
    }

    el?.scrollIntoView({ behavior: 'smooth' });
  });
};

export const beforeUnloadHandler = (event: Event) => {
  event.preventDefault();
  event.returnValue = true;
};

export const getMixedClassesState = (itinerary: PortalFlightItineraryGroupNode) => {
  const classes = new Set();
  itinerary.segments.forEach((segment) => classes.add(segment.flightSegment.serviceClass));
  return classes.size > 1;
};

export const getLongestSegment = (itinerary: PortalFlightItineraryGroupNode) => {
  return itinerary.segments.find((segment) => segment.longest) || itinerary.segments[0];
};

export const setMaxHeight = () => {
  const vh = Math.floor(window.innerHeight * 0.01 * 100);
  document.documentElement.style.setProperty('--mobile-modal-height', `calc(${vh}px - 32px)`);
};

export const mapPortalPassengerNode = (p: PortalPassengerNode) => {
  return {
    value: p.id,
    label: p.fullName,
    extra: {
      birthday: p.birthday,
      firstName: p.firstName,
      gender: p.gender,
      lastName: p.lastName,
      passportCountryId: p.countryId,
    },
  } as Option;
};

export const mapPortalCreditCardNode = (c: PortalCreditCardNode) => {
  return {
    value: c.id,
    label: `${toLabel(c.type)} **** ${c.number.slice(-4)}`,
    extra: {
      holder: c.holder,
      expiration: c.expiration,
    },
  } as Option;
};
