<div class="d-flex align-items-center justify-content-between header">
  <img src="assets/images/bcc_logo.svg" alt="BCC" width="124" height="32" />

  <div class="d-flex align-items-center">
    <div
      *ngIf="salesAgent"
      (click)="toggleDetails(detailsTemplate)"
      class="d-flex align-items-center cursor-pointer agent"
      data-id="agent"
    >
      <img [src]="salesAgent.picture" alt="agent" class="picture" />

      <div class="d-flex flex-column ml-2">
        <p data-id="sales-agent-fullname">{{ salesAgent.fullName }}</p>

        <p class="caption text-jazlyn-700">Your travel expert</p>
      </div>

      <card
        *ngIf="showedDetails"
        (clickOutside)="showedDetails = false"
        class="d-flex flex-column align-items-center p-6 details cursor-default"
      >
        <img [src]="salesAgent.picture" alt="agent" class="details-picture" />

        <h3 class="semi-bold mt-4" data-id="sales-agent-fullname">{{ salesAgent.fullName }}</h3>

        <p *ngIf="salesAgent.shift" class="mt-1">
          {{ salesAgent.shift.startDateTimeAtUtc | date: 'shortTime' }} – {{
          salesAgent.shift.endDateTimeAtUtc | date: 'shortTime' }}
        </p>

        <div class="d-flex mt-2">
          <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" class="mr-1" />
          <a href="tel:{{ salesAgent.phone }}" class="link">
            <p class="text-bleu-de-france-600" data-id="sales-agent-phone">
              {{ salesAgent.phone | phone }}
            </p>
          </a>
        </div>

        <div class="d-flex mt-2">
          <img src="assets/icons/mail.svg" width="20" height="20" alt="mail" class="mr-1" />
          <a href="mailto:{{ salesAgent.email }}" class="link">
            <p class="text-bleu-de-france-600 nowrap" data-id="sales-agent-email">
              {{ salesAgent.email }}
            </p>
          </a>
        </div>
      </card>
    </div>

    <a href="tel:{{ salesAgent?.phone || bccPhone }}" class="link">
      <button class="button secondary d-none d-md-flex align-items-center ml-5">
        <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" class="mr-2" />
        {{ (salesAgent?.phone || bccPhone) | phone }}
      </button>
    </a>

    <div
      *ngIf="user$ | async as user; else loginTemplate"
      (click)="toggleUser(userTemplate)"
      class="ml-5 cursor-pointer d-flex align-items-center user"
    >
      <img src="assets/icons/menu.svg" width="20" height="20" alt="menu" />

      <img [src]="user.picture || 'assets/icons/avatar.svg'" alt="user" class="picture ml-2" />

      <ng-template #userTemplate>
        <div class="d-flex flex-column cursor-default">
          <div class="p-4 d-flex align-items-center">
            <img
              [src]="user.picture || 'assets/icons/avatar.svg'"
              alt="user"
              class="popup-picture"
            />

            <div class="d-flex flex-column ml-3">
              <p>{{ user.fullname }}</p>
              <p class="mt-1 text-jazlyn-700">{{ user.email }}</p>
            </div>
          </div>

          <hr />

          <div
            (click)="showedUser = false; signOut.emit()"
            class="p-4 d-flex align-items-center cursor-pointer"
          >
            <img src="assets/icons/logout.svg" alt="logout" />
            <p class="ml-2">Logout</p>
          </div>
        </div>
      </ng-template>

      <card *ngIf="showedUser" (clickOutside)="showedUser = false" class="user-details">
        <ng-container *ngTemplateOutlet="userTemplate"></ng-container>
      </card>
    </div>

    <ng-template #loginTemplate>
      <button *ngIf="showLoginButton" (click)="signIn.emit()" class="button ml-5">Sign in</button>
    </ng-template>
  </div>
</div>

<ng-template #detailsTemplate>
  <div
    *ngIf="salesAgent"
    (touchstart)="$event.stopPropagation()"
    class="d-flex justify-content-between p-4"
  >
    <div class="d-flex align-items-center">
      <img [src]="salesAgent.picture" alt="agent" class="picture" />

      <div class="d-flex flex-column ml-3">
        <h3 class="semi-bold" data-id="sales-agent-fullname">{{ salesAgent.fullName }}</h3>

        <p *ngIf="salesAgent.shift">
          {{ salesAgent.shift.startDateTimeAtUtc | date: 'shortTime' }} – {{
          salesAgent.shift.endDateTimeAtUtc | date: 'shortTime' }}
        </p>
      </div>
    </div>

    <div class="d-flex">
      <a href="tel:{{ salesAgent.phone }}">
        <button class="button secondary icon-button">
          <img src="assets/icons/phone.svg" width="20" height="20" alt="phone" />
        </button>
      </a>

      <a href="mailto:{{ salesAgent.email }}" class="ml-2">
        <button class="button secondary icon-button">
          <img src="assets/icons/mail.svg" width="20" height="20" alt="mail" />
        </button>
      </a>
    </div>
  </div>
</ng-template>
