<div class="d-flex align-items-center justify-content-between px-4 pt-4 px-md-8 pt-md-8">
  <h1 class="semi-bold">Continue to your account</h1>

  <img
    (click)="hideModal.emit()"
    src="assets/icons/close.svg"
    alt="close"
    class="cursor-pointer d-none d-md-inline"
    data-id="hide"
  />
</div>

<p class="mt-2 mx-4 mx-md-8">
  Get better deals, save your details for easy booking, and see all your trips in one place.
</p>

<div class="d-flex flex-column mx-4 mx-md-8 mb-4 mb-mx-8">
  <button
    (click)="continueWithGoogle.emit()"
    class="button secondary d-flex align-items-center justify-content-center mt-8"
    data-id="google"
  >
    <img src="assets/icons/google.svg" alt="google" class="mr-2" />Continue with Google
  </button>

  <button
    (click)="continueWithFacebook.emit()"
    class="button secondary d-flex align-items-center justify-content-center mt-4"
    data-id="facebook"
  >
    <img src="assets/icons/facebook.svg" alt="facebook" class="mr-2" />Continue with Facebook
  </button>
</div>
