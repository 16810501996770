<ng-container *ngIf="portalLead$ | async as portalLead">
  <ng-container *ngIf="portalLead.salesAgent; else searchingTravelExpert">
    <ng-container *ngIf="portalLead.flightRequest; else travelExpertInfo">
      <flight-info [portalLead]="portalLead" class="mx-4 mx-md-0"></flight-info>

      <stepper [step]="Steps.PickYourFlight" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

      <div class="my-4 my-md-8">
        <portal-option
          *ngFor="let option of portalLead.flightRequest.publishedOptions?.edges | extractNodes"
          (requestToBook)="requestToBook(option)"
          [option]="option"
          [portalLink]="portalLead.flightRequest.portalLink"
          [withHiddenFlightsV2]="withHiddenFlightsV2"
          class="mt-4"
          data-id="option"
          attr.track-scroll="{
            'event_name': 'quote_overview_seen',
            'flight_request_portal_link': '{{ portalLead.flightRequest.portalLink }}',
            'option_portal_link': '{{ option.portalLink }}',
          }"
        ></portal-option>

        <p class="mt-4 mt-md-6 mx-4 mx-md-10 pb-md-7 text-align-center text-jazlyn-700">
          All currency exchange rates calculated as of
          <span data-id="created-at"
            >{{ portalLead.flightRequest.createdAt | date: 'MMM d, yyyy' }}</span
          >, based on rates provided by Global Distribution System. Please note, all transactions
          are to be charged in USD
        </p>
      </div>
    </ng-container>
  </ng-container>

  <ng-template #searchingTravelExpert>
    <div class="my-4 my-md-8">
      <searching-for-a-travel-expert class="mt-4 mt-md-8"></searching-for-a-travel-expert>
    </div>
  </ng-template>

  <ng-template #travelExpertInfo>
    <flight-info [portalLead]="portalLead" class="mx-4 mx-md-0"></flight-info>

    <stepper [step]="Steps.MeetYourExpert" class="mt-3 mt-md-4 mx-4 mx-md-0"></stepper>

    <ng-container *ngIf="portalLead.salesAgent">
      <card class="mt-6 mt-md-8 flex-column align-items-center agent">
        <img [src]="portalLead.salesAgent.picture" alt="agent" class="picture" />

        <p class="mt-6 text-align-center">
          <b data-id="fullname">{{ portalLead.salesAgent.fullName }}</b>, your Travel Expert, will
          reach out soon to discuss your trip<br class="d-none d-md-inline" />
          and savings. Giving {{ portalLead.salesAgent.fullName | getFirstName }} your trip details
          will help us save you money.
        </p>

        <div class="d-flex justify-content-center flex-wrap mt-2">
          <div class="px-2 py-1 badge m-1">
            <p data-id="deals">{{ portalLead.salesAgent.deals }} planned trips</p>
          </div>

          <div class="px-2 py-1 badge m-1">
            <p data-id="avg-savings">
              Saves clients {{ portalLead.salesAgent.avgSavings | portalCurrency }} on average
            </p>
          </div>

          <div class="px-2 py-1 badge m-1">
            <p>97% client happiness score</p>
          </div>
        </div>
      </card>

      <reviews [salesAgent]="portalLead.salesAgent" class="my-4 my-md-8 mx-4 mx-md-0"></reviews>
    </ng-container>
  </ng-template>
</ng-container>

<ng-template #hiddenFlightsTemplate>
  <hidden-flights-modal
    (hiddenFlightsPresented)="getPortalLead()"
    (requestToBook)="submitRequestToBook(); hideModal()"
    (hideModal)="hideModal()"
    [option]="portalOrder"
  ></hidden-flights-modal>
</ng-template>

<ng-template #signInTemplate>
  <sign-in-modal
    (hideModal)="hideModal()"
    (continueWithGoogle)="continueWith(CognitoHostedUIIdentityProvider.Google)"
    (continueWithFacebook)="continueWith(CognitoHostedUIIdentityProvider.Facebook)"
  ></sign-in-modal>
</ng-template>

<ng-template #askToSignInTemplate>
  <ask-to-sign-in-modal
    (hideModal)="hideModal()"
    (continueAsGuest)="submitRequestToBook(); hideModal()"
    (showSignInModal)="showSignInModal()"
  ></ask-to-sign-in-modal>
</ng-template>

<ng-template #bookingErrorTemplate>
  <book-error-modal (refreshPage)="refreshPage()"></book-error-modal>
</ng-template>
