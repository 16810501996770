import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { interval, map, Observable, Subject, takeUntil } from 'rxjs';

import { PortalUserVerificationNode } from '@app/services/api/api.types';

@Component({
  selector: 'verification-modal',
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationModalComponent implements OnInit, OnChanges, AfterViewInit {
  domain: string;
  form: FormGroup;

  timer$: Observable<number>;

  loading: boolean;

  @Input() verification: PortalUserVerificationNode;
  @Input() verificationPassed: boolean;
  @Input() verificationError: string | null;

  @Output() hideModal = new EventEmitter();
  @Output() validateCode = new EventEmitter();
  @Output() portalSendVerificationEmail = new EventEmitter();

  ngOnInit() {
    this.domain = getDomain(this.verification?.emailMasked);

    this.form = new FormGroup({
      digit1: new FormControl('', Validators.pattern('[0-9]')),
      digit2: new FormControl('', Validators.pattern('[0-9]')),
      digit3: new FormControl('', Validators.pattern('[0-9]')),
      digit4: new FormControl('', Validators.pattern('[0-9]')),
    });

    this.startCountdown();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['verificationError']?.currentValue === 'string') {
      this.form.setValue({
        digit1: '',
        digit2: '',
        digit3: '',
        digit4: '',
      });
      this.form.setErrors({});
      this.focusFirstInput();
    }

    if (changes['verificationPassed']?.currentValue === true) {
      setTimeout(() => this.hideModal.emit(), 1000);
    }
  }

  ngAfterViewInit() {
    this.focusFirstInput();
  }

  focusFirstInput() {
    setTimeout(() => document.getElementById('digit1')?.focus());
  }

  onChange(event: Event) {
    this.form.setErrors(null);

    const input = event.target as HTMLInputElement;
    const nextInput = input.nextElementSibling as HTMLInputElement;

    input.value = input.value.replace(/[^0-9]/g, '');

    if (input.value.length === 1 && nextInput) {
      nextInput.focus();
    }

    if (this.form.valid) {
      this.onSubmit();
    }
  }

  onSubmit() {
    const code = Object.values(this.form.value as string[]).join('');
    this.validateCode.emit(code);
  }

  sendVerificationEmail() {
    if (!this.loading) {
      this.portalSendVerificationEmail.emit();
    }

    this.loading = true;
  }

  startCountdown() {
    const destroy$ = new Subject<void>();

    const endTime =
      new Date(`${this.verification.emailSentAt.split('+')[0]}+00:00`).getTime() + intervalMs; // todo fix after be

    this.timer$ = interval(1000)
      .pipe(takeUntil(destroy$))
      .pipe(
        map(() => {
          const now = new Date().getTime();

          const currentTime = endTime - now;

          if (currentTime <= 0) {
            setTimeout(() => {
              destroy$.next();
              destroy$.complete();
            });
          }

          return currentTime;
        })
      );
  }
}

const getDomain = (email = '') => {
  const regex = /^([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
  const match = email.match(regex) as string[];

  if (match) {
    const domain = match[2].toLowerCase();

    if (domain.includes('gmail.com')) {
      return 'https://gmail.com';
    } else if (domain.includes('yahoo.com') || domain.includes('myyahoo.com')) {
      return 'https://mail.yahoo.com';
    } else if (
      domain.includes('outlook.com') ||
      domain.includes('hotmail.com') ||
      domain.includes('live.com') ||
      domain.includes('msn.com')
    ) {
      return 'https://outlook.live.com';
    }
  }

  return '';
};

export const intervalMs = 120000;
