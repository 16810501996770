<div class="d-flex align-items-center justify-content-between px-4 pt-4 px-md-8 pt-md-8">
  <h1 class="semi-bold">Enter code</h1>

  <img
    (click)="hideModal.emit()"
    src="assets/icons/close.svg"
    alt="close"
    class="cursor-pointer d-none d-md-inline"
    data-id="hide"
  />
</div>

<p class="mt-2 mx-4 mx-md-8">
  We’ve sent an activation code to your email <b data-id="email">{{ verification.emailMasked }}</b>.
  Check junk mail if it’s not in your inbox.
</p>

<p *ngIf="domain" class="mt-4 mx-4 mx-md-8">
  <a
    (click)="$event.stopPropagation()"
    [href]="domain"
    target="_blank"
    class="link text-bleu-de-france-600 d-flex"
    >Open mail<img src="assets/icons/external_link.svg" alt="external_link" class="ml-1"
  /></a>
</p>

<form [formGroup]="form" class="mt-6 mx-4 mx-md-8 mb-8">
  <div class="d-flex align-items-center nowrap wrapper">
    <input
      (input)="onChange($event)"
      [ngClass]="{ 'error': form.touched && form.errors, 'passed': verificationPassed }"
      type="text"
      formControlName="digit1"
      maxlength="1"
      required
      class="d-flex flex-1 mx-1 input"
      data-id="digit1"
      id="digit1"
    />
    <input
      (input)="onChange($event)"
      [ngClass]="{ 'error': form.touched && form.errors, 'passed': verificationPassed }"
      type="text"
      formControlName="digit2"
      maxlength="1"
      required
      class="d-flex flex-1 mx-1 input"
      data-id="digit2"
      id="digit2"
    />
    <input
      (input)="onChange($event)"
      [ngClass]="{ 'error': form.touched && form.errors, 'passed': verificationPassed }"
      type="text"
      formControlName="digit3"
      maxlength="1"
      required
      class="d-flex flex-1 mx-1 input"
      data-id="digit3"
      id="digit3"
    />
    <input
      (input)="onChange($event)"
      [ngClass]="{ 'error': form.touched && form.errors, 'passed': verificationPassed }"
      type="text"
      formControlName="digit4"
      maxlength="1"
      required
      class="d-flex flex-1 mx-1 input"
      data-id="digit4"
      id="digit4"
    />
  </div>

  <p *ngIf="form.errors" class="mt-1 caption text-geranium-lake-700" data-id="error">
    {{ verificationError }}
  </p>

  <ng-container *ngIf="timer$ | async as timer">
    <p *ngIf="timer > 0; else resendTemplate" class="mt-6 text-jazlyn-700">
      Resend code in {{ timer | formatMs }}
    </p>
  </ng-container>

  <ng-template #resendTemplate>
    <a
      (click)="$event.preventDefault(); sendVerificationEmail()"
      class="link text-bleu-de-france-600 d-flex mt-6"
      data-id="resend"
      ><p>Send code</p></a
    >
  </ng-template>
</form>
