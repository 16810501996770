import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'continue-with-email-modal',
  templateUrl: './continue-with-email-modal.component.html',
  styleUrls: ['./continue-with-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContinueWithEmailModalComponent implements OnInit, OnChanges {
  form: FormGroup;

  loading: boolean;

  @Input() activationError: string | null;

  @Output() hideModal = new EventEmitter();
  @Output() sendEmail = new EventEmitter();

  @HostListener('document:keydown.enter') onEnter() {
    this.submit();
  }

  ngOnInit() {
    this.form = new FormGroup({ email: new FormControl('') });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (typeof changes['activationError']?.currentValue === 'string') {
      this.loading = false;

      this.form.enable();
      this.form.setErrors({});
    }
  }

  submit() {
    this.loading = true;

    this.form.disable();

    this.sendEmail.emit(this.form.get('email')?.value);
  }
}
