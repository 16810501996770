export const bccPhone = '+18663467848';

export const bccUrl = 'https://businessclassconsolidator.com';

export const containerWidth = 848;

export const newTravalerOption = {
  value: 'new',
  label: 'New traveler',
};

export const newCreditCardOption = {
  value: 'new',
  label: 'New debit or credit card',
};
