import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Subscription, map, of } from 'rxjs';

import { BaseFormComponent } from '@app/forms/formly/base-form/base-form';
import { ApiService } from '@app/services/api/api.service';
import { dropdownSeparatorValue } from '@app/ui/components/dropdown/dropdown.component';
import { CCNumberValidator, errorsFormTexts } from '@app/forms/formly/validators/validators';
import { scrollTo } from '@app/utils/utils';
import { Option } from '@app/forms/formly/formly-utils';
import { newCreditCardOption } from '@app/utils/constants';

@Component({
  selector: 'card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardFormComponent
  extends BaseFormComponent<CreditCardErrors | null>
  implements AfterViewInit, OnDestroy
{
  valueChanges$: Subscription;
  fieldValueChanges$?: Subscription;

  usCountryCode = '';

  @Input() readAndAccept: boolean;
  @Input() clientCreditCards: Option[];

  @Output() valueChange = new EventEmitter();
  @Output() setReadAndAcceptError = new EventEmitter();
  @Output() back = new EventEmitter();

  constructor(private apiService: ApiService) {
    super();
  }

  ngAfterViewInit() {
    this.valueChanges$ = this.form.valueChanges.subscribe(() => this.valueChange.emit());
  }

  override getFields(): FormlyFieldConfig[] {
    return [
      {
        key: 'id',
        type: 'dropdown',
        className: 'mb-4',
        props: {
          placeholder: 'Add a passenger',
          options: of(this.clientCreditCards),
        },
        defaultValue: newCreditCardOption.value,
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            let prevValue: unknown = field.formControl?.value;

            this.fieldValueChanges$ = field.formControl?.valueChanges.subscribe((value) => {
              if (value === prevValue) return;

              prevValue = value;

              if (value === newCreditCardOption.value) {
                field.parent?.formControl?.reset({ id: newCreditCardOption.value });
              } else {
                const foundCreditCard = this.clientCreditCards?.find((p) => p.value === value);

                const formValue = { ...(foundCreditCard?.extra || {}) };

                field.parent?.formControl?.patchValue(formValue);
              }
            });
          },
        },
        expressions: {
          hide: () => !this.clientCreditCards?.length,
        },
      },
      {
        key: 'number',
        type: 'cc-number',
        className: 'mb-4',
        props: {
          label: 'Card number',
          required: true,
          placeholder: '0000 0000 0000 0000',
        },
        validators: {
          'cc-number-invalid': CCNumberValidator,
        },
        expressions: {
          hide: this.isDisabled.bind(this),
          'props.disabled': this.isDisabled.bind(this),
        },
      },
      {
        key: 'holder',
        type: 'input',
        className: 'mb-4',
        props: {
          label: 'Cardholder name',
          required: true,
        },
        expressions: {
          'props.disabled': this.isDisabled.bind(this),
        },
      },
      {
        fieldGroup: [
          {
            key: 'expiration',
            type: 'cc-expiration',
            className: 'mr-2 flex-1',
            props: {
              label: 'Expiration date',
              required: true,
              placeholder: 'MM/YY',
            },
            expressions: {
              'props.disabled': this.isDisabled.bind(this),
            },
          },
          {
            key: 'cvv2',
            type: 'cc-cvv',
            className: 'ml-2 flex-1',
            props: {
              label: 'Security code',
              required: true,
            },
          },
        ],
        fieldGroupClassName: 'd-flex mb-4',
      },
      {
        key: 'countryId',
        type: 'dropdown',
        className: 'mb-4',
        props: {
          label: 'Country',
          placeholder: 'Select country',
          required: true,
          options: this.apiService.countries().pipe(
            map((options) => {
              const separatedOptions = ['US', 'GB', 'CA', 'AU'].map((code) => {
                const index = options.findIndex(({ extra }) => extra?.code === code);
                return options.splice(index, 1)[0];
              });

              this.usCountryCode = separatedOptions[0]?.value;

              return [...separatedOptions, { value: dropdownSeparatorValue }, ...options];
            })
          ),
        },
        expressions: {
          hide: this.isDisabled.bind(this),
          'props.disabled': this.isDisabled.bind(this),
        },
      },
      {
        key: 'billingAddress1',
        type: 'input',
        className: 'mb-4',
        props: {
          label: 'Address line 1',
          required: true,
        },
        expressions: {
          hide: this.isDisabled.bind(this),
          'props.disabled': this.isDisabled.bind(this),
        },
      },
      {
        key: 'billingAddress2',
        type: 'input',
        className: 'mb-4',
        props: {
          label: 'Address line 2 (optional)',
        },
        expressions: {
          hide: this.isDisabled.bind(this),
        },
      },
      {
        fieldGroup: [
          {
            key: 'state',
            type: 'dropdown',
            className: 'mr-2 flex-1',
            props: {
              label: 'State',
              placeholder: 'Select state',
              required: true,
              options: this.apiService.states(),
            },
            expressions: {
              hide: () => {
                const countryId = (this.model as Partial<{ countryId: string }>)['countryId'];

                return (countryId && countryId !== this.usCountryCode) || this.isDisabled();
              },
              'props.disabled': this.isDisabled.bind(this),
            },
          },
          {
            key: 'city',
            type: 'input',
            props: {
              label: 'City',
              required: true,
            },
            expressions: {
              className: () => {
                const countryId = (this.model as Partial<{ countryId: string }>)['countryId'];

                return countryId
                  ? countryId === this.usCountryCode
                    ? 'ml-2 flex-1'
                    : 'flex-1'
                  : 'ml-2 flex-1';
              },
              hide: this.isDisabled.bind(this),
              'props.disabled': this.isDisabled.bind(this),
            },
          },
        ],
        fieldGroupClassName: 'd-flex mb-4',
      },
      {
        key: 'zipCode',
        type: 'input',
        className: 'pr-2 w-50',
        props: {
          label: 'ZIP code',
          required: true,
        },
        expressions: {
          hide: this.isDisabled.bind(this),
          'props.disabled': this.isDisabled.bind(this),
        },
      },
    ];
  }

  isDisabled() {
    const idControl = this.form.get('id');
    return idControl?.value !== newCreditCardOption.value && this.clientCreditCards?.length;
  }

  override submit() {
    this.form.markAllAsTouched();

    if (this.form.valid && this.readAndAccept) {
      this.form.disable();
      this.formSubmit.emit(this.model);
    } else if (this.form.invalid) {
      this.form.setErrors({ reqired: errorsFormTexts.required });
      this.scrollToFirstError();
    } else {
      this.setReadAndAcceptError.emit(true);
      scrollTo('#read-and-accept');
    }

    const lead_portal_link = document.location.pathname.substring(1);
    window.track({ event_name: 'submit_card', lead_portal_link });
  }

  override setErrors(errors: CreditCardErrors) {
    const keys = Object.keys(errors.credit_card || {});

    if (keys?.length) {
      this.form.enable();

      keys.forEach((key) => {
        const field = this.form.get(key);

        field?.setErrors({ backend: (errors.credit_card || {})[key] });
      });

      this.form.setErrors({ backend: errorsFormTexts.card });

      this.scrollToFirstError();
    }
  }

  ngOnDestroy() {
    this.valueChanges$?.unsubscribe();
    this.fieldValueChanges$?.unsubscribe();
  }
}

export type CreditCardErrors = {
  passengers?: {
    birthday?: string;
  }[];
  credit_card?: {
    [key: string]: string;
  };
};
