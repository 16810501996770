import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { catchError, EMPTY, map, Observable, take } from 'rxjs';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { ActivatedRoute, Router } from '@angular/router';

import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import {
  PortalFeatures,
  PortalLeadNode,
  PortalOrderNode,
  PortalOrderStatusType,
  PortalUserVerificationNode,
} from '@app/services/api/api.types';
import { PopupsService } from '@app/ui/services/popups.service';
import { PortalOrderService } from '@app/services/portal-order/portal-order.service';
import { AuthService } from '@app/services/auth/auth.service';
import { ApiService } from '@app/services/api/api.service';
import { Steps } from '@app/components/stepper/stepper.component';
import { StateService } from '@app/services/state/state.service';
import { passwordSalt } from '@app/utils/constants';

@Component({
  selector: 'lead-page',
  templateUrl: './lead-page.component.html',
  styleUrls: ['./lead-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadPageComponent implements OnInit {
  Steps = Steps;
  CognitoHostedUIIdentityProvider = CognitoHostedUIIdentityProvider;

  portalLead$: Observable<PortalLeadNode | null>;

  portalOrder: PortalOrderNode;

  withAuth: boolean;
  withHiddenFlights: boolean;
  withHiddenFlightsV2: boolean;
  withPayAndConfirm: boolean;

  email: string;

  activation: PortalUserVerificationNode;
  activationPassed: boolean;
  activationError: string | null;

  @ViewChild('hiddenFlightsTemplate') hiddenFlightsTemplate: TemplateRef<unknown>;
  @ViewChild('signInTemplate') signInTemplate: TemplateRef<unknown>;
  @ViewChild('askToSignInTemplate') askToSignInTemplate: TemplateRef<unknown>;
  @ViewChild('bookingErrorTemplate') bookingErrorTemplate: TemplateRef<unknown>;
  @ViewChild('continueWithEmailTemplate') continueWithEmailTemplate: TemplateRef<unknown>;
  @ViewChild('activationCodeTemplate') activationCodeTemplate: TemplateRef<unknown>;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private portalLeadService: PortalLeadService,
    private portalOrderService: PortalOrderService,
    private activatedRoute: ActivatedRoute,
    private stateService: StateService,
    private popupsService: PopupsService,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getPortalLead();
  }

  getPortalLead() {
    this.portalLead$ = this.portalLeadService.portalLead$.pipe(
      map((portalLead) => {
        if (portalLead) {
          this.withAuth = portalLead.salesAgent.portalFeatures.includes(PortalFeatures.portal_auth);

          this.withHiddenFlights = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_hidden_flights
          );

          this.withHiddenFlightsV2 = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_hidden_flights_v2
          );

          this.withPayAndConfirm = portalLead.salesAgent.portalFeatures.includes(
            PortalFeatures.portal_cc
          );
        }
        return portalLead;
      })
    );
  }

  requestToBook(portalOrder: PortalOrderNode) {
    this.hideModal();

    this.stateService.resetState();

    this.portalOrderService.setPortalOrder(portalOrder);
    this.portalOrder = portalOrder;

    const needsToPresentHiddenFlights =
      (portalOrder.hasHiddenCityTicketing || portalOrder.hasThrowawayTicketing) &&
      !portalOrder.hiddenFlightsPresented &&
      this.withHiddenFlights;

    if (needsToPresentHiddenFlights) {
      return this.showPresentHiddenFlightsModal();
    }

    if (this.withAuth) {
      return this.checkSession();
    }

    this.submitRequestToBook();
  }

  submitRequestToBook() {
    if (this.portalOrder.isChange) {
      this.portalUpdateOption();
      return;
    }

    const portalLink = this.stateService.getState().portalLink;
    const queryParams = this.activatedRoute.snapshot.queryParams;

    void this.router.navigate([`${portalLink}`, `${this.portalOrder.portalLink}`, `passengers`], {
      queryParams,
    });
  }

  portalUpdateOption() {
    const variables = {
      inputUpdate: {
        portalLink: this.portalOrder.portalLink,
        status: PortalOrderStatusType.OptionBooked,
      },
    };

    return this.apiService
      .portalUpdateOption(variables)
      .pipe(
        take(1),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe((response) => {
        this.hideModal();

        if (response?.ok) {
          const portalLink = this.stateService.getState().portalLink;

          this.portalLeadService.getPortalLead({ portalLink });

          void this.router.navigate([
            `${portalLink}`,
            `${this.portalOrder.portalLink}`,
            `thank-you`,
          ]);
        } else {
          this.showBookingErrorModal();
        }
      });
  }

  checkSession() {
    this.authService
      .hasSession()
      .pipe(
        take(1),
        map((hasSession) => {
          if (hasSession) {
            return this.submitRequestToBook();
          }
          this.showAskToSignInModal();
        }),
        catchError((error: Error) => {
          console.log(error.message || error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  continueWith(provider: CognitoHostedUIIdentityProvider) {
    const url = `${window.location.href}/?portalLink=${this.portalOrder.portalLink}`;
    void this.authService.federatedSignIn(provider, url);
  }

  showPresentHiddenFlightsModal() {
    this.popupsService.showModal(this.hiddenFlightsTemplate, { className: 'wide' });
  }

  showSignInModal() {
    this.hideModal();
    this.popupsService.showModal(this.signInTemplate);
  }

  showAskToSignInModal() {
    this.popupsService.showModal(this.askToSignInTemplate);
  }

  showBookingErrorModal() {
    this.popupsService.showModal(this.bookingErrorTemplate);
  }

  hideModal() {
    this.popupsService.hideLast();
  }

  refreshPage() {
    window.location.reload();
  }

  continueWithEmail() {
    this.hideModal();
    this.popupsService.showModal(this.continueWithEmailTemplate);
  }

  sendEmail(email: string) {
    this.activationError = null;
    this.email = email;

    const portalLink = this.stateService.getState().portalLink;

    const variables = { portalLink, email };

    this.apiService
      .sendPasswordToEmail(variables)
      .pipe(take(1))
      .subscribe(({ errors }) => {
        if (errors?.length) {
          this.activationError = errors[0].message;
        } else {
          this.hideModal();

          this.activation = {
            emailMasked: this.email,
            emailSentAt: new Date().toUTCString(),
          };

          this.popupsService.showModal(this.activationCodeTemplate);
        }

        this.changeDetectorRef.detectChanges();
      });
  }

  validateNewEmailCode(password: string) {
    this.activationPassed = false;
    this.activationError = null;

    void this.authService
      .signIn(this.email, `${password}${atob(passwordSalt)}`)
      .pipe(
        take(1),
        map(() => {
          this.activationPassed = true;

          const url = `${window.location.href}/?portalLink=${this.portalOrder.portalLink}`;

          setTimeout(() => (window.location.href = url), 1000);

          this.changeDetectorRef.detectChanges();
        }),
        catchError((error: Error) => {
          console.log(error.message || error);

          this.activationError = error.message;

          this.changeDetectorRef.detectChanges();

          return EMPTY;
        })
      )
      .subscribe();
  }
}
