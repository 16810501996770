<div class="d-flex flex-column">
  <div class="mb-4 p-4 d-flex align-items-center block">
    <img src="assets/icons/error-orange.svg" alt="error" />
    <p class="text-sun-crete-700 ml-1">Traveler details must match their passports.</p>
  </div>

  <form [formGroup]="form">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  </form>

  <div *ngIf="form.touched && form.errors" class="d-none d-md-block">
    <form-error
      *ngFor="let error of form.errors | keyvalue"
      [error]="error"
      class="mt-4"
      data-id="error"
    ></form-error>
  </div>
</div>

<div class="d-md-none p-fixed errors">
  <div *ngIf="form.touched && form.errors" class="mx-4">
    <form-error
      *ngFor="let error of form.errors | keyvalue"
      [error]="error"
      class="mb-2"
      data-id="error"
    ></form-error>
  </div>
</div>

<ng-content></ng-content>

<div class="mt-4 mt-md-6 mx-4 mx-md-0 d-flex flex-column-reverse flex-md-row">
  <button
    (click)="back.emit()"
    class="button secondary mt-4 mt-md-0 d-flex align-items-center justify-content-center"
    data-id="back"
  >
    <img src="assets/icons/chevron_left_blue.svg" alt="chevron" class="mr-2" />
    Back
  </button>

  <button
    (click)="submit()"
    [disabled]="form.disabled"
    class="button ml-md-auto d-flex align-items-center justify-content-center"
    data-id="submit"
  >
    Continue
    <img src="assets/icons/chevron_right_white.svg" alt="chevron" class="ml-2" />
  </button>
</div>
