import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, EMPTY, map, Observable, of, Subscription, switchMap, take } from 'rxjs';

import { PortalLeadService } from '@app/services/portal-lead/portal-lead.service';
import {
  AuthorizedDataNode,
  PortalFeatures,
  PortalLeadNode,
  PortalUserVerificationNode,
} from '@app/services/api/api.types';
import { bccUrl, newCreditCardOption, newTravalerOption } from '@app/utils/constants';
import { StateService } from '@app/services/state/state.service';
import { PopupsService } from '@app/ui/services/popups.service';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { AuthService } from '@app/services/auth/auth.service';
import { ApiService } from '@app/services/api/api.service';
import { mapPortalCreditCardNode, mapPortalPassengerNode } from '@app/utils/utils';

@Component({
  selector: 'lead-wrapper-page',
  templateUrl: './lead-wrapper-page.component.html',
  styleUrls: ['./lead-wrapper-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeadWrapperPageComponent implements OnInit, OnDestroy {
  CognitoHostedUIIdentityProvider = CognitoHostedUIIdentityProvider;

  portalLead$: Observable<PortalLeadNode | null>;
  router$: Subscription;

  withAuth: boolean;
  showLoginButton: boolean;

  verification: PortalUserVerificationNode;
  verificationPassed: boolean;
  verificationError: string | null;

  constructor(
    private portalLeadService: PortalLeadService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private popupsService: PopupsService,
    private stateService: StateService,
    private authService: AuthService,
    private apiService: ApiService,
    private router: Router
  ) {}

  @ViewChild('signInTemplate') signInTemplate: TemplateRef<unknown>;
  @ViewChild('verificationTemplate') verificationTemplate: TemplateRef<unknown>;

  ngOnInit() {
    this.router$ = this.router.events.subscribe(this.handleShowLoginButtonVisibility.bind(this));

    this.getPortalLead();
  }

  getPortalLead() {
    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    this.portalLeadService.getPortalLead({ portalLink });

    this.portalLead$ = this.portalLeadService.portalLead$.pipe(
      map((portalLead) => {
        this.stateService.patchState({ portalLink });

        if (portalLead) {
          if (portalLead.flightRequest?.isLost) {
            window.location.href = bccUrl;
          }

          this.withAuth =
            portalLead.salesAgent.portalFeatures.includes(PortalFeatures.portal_auth) &&
            portalLead.clientHasGmail;

          this.handleShowLoginButtonVisibility();
        }

        return portalLead;
      }),
      switchMap((portalLead: PortalLeadNode | null) => {
        if (this.withAuth) {
          return this.initSessionUser(portalLead);
        }

        return of(portalLead);
      }),
      catchError((error: Error) => {
        console.log(error.message || error);
        return EMPTY;
      })
    );
  }

  initSessionUser(portalLead: PortalLeadNode | null) {
    return this.authService.hasSession().pipe(
      switchMap((hasSession) => {
        if (hasSession) {
          return this.authService.initSessionUser();
        }

        throw new Error('Invalid session: initSessionUser');
      }),
      switchMap(() => this.getAuthorizedData()),
      switchMap(() => of(portalLead)),
      catchError((error: Error) => {
        console.log(error.message || error);
        return of(portalLead);
      })
    );
  }

  getAuthorizedData() {
    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink };

    return this.apiService
      .authorizedData(variables)
      .pipe(map((data) => this.handleAuthorizedData(data)));
  }

  handleAuthorizedData(data: AuthorizedDataNode) {
    const clientPassengers = data.passengers.map(mapPortalPassengerNode);

    if (clientPassengers.length) {
      clientPassengers.unshift(newTravalerOption);
    }

    this.stateService.setClientPassengers(clientPassengers);

    const clientCreditCards = data.creditCards.map(mapPortalCreditCardNode);

    if (clientCreditCards.length) {
      clientCreditCards.unshift(newCreditCardOption);
    }

    this.stateService.setClientCreditCards(clientCreditCards);

    this.verification = data.verification;

    if (this.verification.required) {
      this.showVerificationModal();
    } else {
      this.tryPreselectOption();
    }
  }

  tryPreselectOption() {
    const url = new URL(window.location.href);
    const portalLink = url.searchParams.get('portalLink');

    if (portalLink) {
      setTimeout(() => {
        document.getElementById(`submit-${portalLink}`)?.click();
        void this.router.navigate([], { queryParams: {} });
      });
    }
  }

  showVerificationModal() {
    this.popupsService.showModal(this.verificationTemplate);
  }

  validateCode(verificationCode: string) {
    this.verificationPassed = false;
    this.verificationError = null;

    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink, verificationCode };

    this.apiService
      .portalAcceptVerification(variables)
      .pipe(take(1))
      .subscribe(({ data, errors }) => {
        if (errors?.length) {
          this.verificationError = errors[0].message;
          this.changeDetectorRef.detectChanges();
        } else {
          this.verificationPassed = true;
          this.changeDetectorRef.detectChanges();

          this.handleAuthorizedData(data.portalAcceptVerification.result);
        }
      });
  }

  portalSendVerificationEmail() {
    const portalLink = this.activatedRoute.snapshot.paramMap.get('lead-id') || '';

    const variables = { portalLink };

    this.apiService
      .portalSendVerificationEmail(variables)
      .pipe(take(1))
      .subscribe(({ result }) => {
        this.hideModal();
        this.handleAuthorizedData(result);

        this.changeDetectorRef.detectChanges();
      });
  }

  handleShowLoginButtonVisibility() {
    const routeLevel = this.router.url.split('/').filter((v) => !!v)?.length; // todo probably bug
    this.showLoginButton = this.withAuth && routeLevel === 1;
  }

  showSignInModal() {
    this.popupsService.showModal(this.signInTemplate);
  }

  continueWith(provider: CognitoHostedUIIdentityProvider) {
    const url = `${window.location.href}`;
    void this.authService.federatedSignIn(provider, url);
  }

  signOut() {
    void this.authService.signOut();
  }

  hideModal() {
    this.popupsService.hideLast();
  }

  ngOnDestroy(): void {
    this.router$?.unsubscribe();
  }
}
